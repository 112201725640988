<template>
  <v-card-title class="headline-box-headline primary" primary-title :style="customStyle">
    <v-row class="headline-box-headline white--text w100" no-gutters>
      <v-col style="white-space: nowrap;"><span class="dialogTitle">{{ customTitle }}</span></v-col>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-row>
  </v-card-title>
</template>
<script>
export default {
  props: ["title", "result"],
  computed: {
    customStyle: function () {
      let customStyle = "";
      if (this.checkStencil(this.result)) {
        customStyle = "background-color:  #80A956 !important;";
      }
      return customStyle;
    },
    customTitle: function () {
      let title = this.title;
      if (this.checkStencil(this.result)) {
        title = this.title.replace("Test", "Stencil");
      }
      return title;
    }
  }
};
</script>